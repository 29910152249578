import { getConfig } from "@portal-frontend-ssr/config";

type Title = {
  title: string;
};

type MetaProperty = {
  property: string;
  content: string;
};

type MetaName = {
  name: string;
  content: string;
};

type Meta = Title | MetaProperty | MetaName;

const baseAssetsURL = getConfig().BASE_ASSET_URL;

export const generateMetaTags = ({
  metaTitle = "Deadlock Hero Tier lists, Win Rates, Pick Rates, Stats, Builds and more!",
  metaDescription = "Stay up-to-date with a tracker and stat breakdown for all your competitive needs.",
  metaImageUrl,
}: {
  metaTitle?: string;
  metaDescription?: string;
  metaImageUrl?: string;
}) => {
  const tags: Meta[] = [];

  tags.push({ title: metaTitle });

  tags.push({ name: "twitter:title", content: metaTitle });
  tags.push({ name: "og:title", content: metaTitle });
  tags.push({ name: "og:url", content: "https://deadlock.blast.tv" });

  // Twitter meta tags used to display the website preview when shared on Twitter
  tags.push({ name: "twitter:card", content: "summary_large_image" });
  tags.push({ name: "twitter:site", content: "@BLASTtv" });
  tags.push({ name: "twitter:creator", content: "@BLASTtv" });

  if (metaDescription) {
    tags.push({ name: "description", content: metaDescription });
    tags.push({ name: "twitter:description", content: metaDescription });
    tags.push({ name: "og:description", content: metaDescription });
  }

  if (metaImageUrl) {
    tags.push({ name: "twitter:image", content: metaImageUrl });
    tags.push({ name: "og:image", content: metaImageUrl });
  } else {
    tags.push({ name: "twitter:image", content: `${baseAssetsURL}/images/deadlock/og-images/generic.png?width=1280` });
    tags.push({ name: "og:image", content: `${baseAssetsURL}/images/deadlock/og-images/generic.png?width=1280` });
  }

  return tags;
};
